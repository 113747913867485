import React from "react"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"
import style from "../components/global/layout/deal.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "@elements/Button"
import Content from "@elements/Content"
import Block from "@global/layout/Block"

const DealDetail = ({ data: { deal }, pageContext: { parent } }) => {
  const { html } = deal.descriptionNode.childMarkdownRemark
  return (
    <Layout parent={parent}>
      <HelmetDatoCms defer={false} seo={deal.seoMetaTags} />
      <div className={style.deal}>
        <div className={style.deal__hero}>
          <GatsbyImage
            className={style.deal__image}
            image={deal.image.gatsbyImageData}
          />
        </div>
        <Block padding="both" gutters={true} maxWidth="inner">
          <div className={style.deal__content}>
            <h1 className={style.deal__title}>{deal.title}</h1>
            {true && <Content html={html} />}
            {true && (
              <Button
                text={deal.link.displayText}
                url={deal.link.url}
                icon={true}
              />
            )}
          </div>
        </Block>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query dealById($id: String) {
    deal: datoCmsDeal(id: { eq: $id }) {
      id
      slug
      title
      description
      code
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      link {
        ...LinkHelper
      }
      image {
        gatsbyImageData(imgixParams: { w: "1600" })
      }
    }
  }
`

export default DealDetail
